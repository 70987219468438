import { BKStyledButton } from '@gigin-work-space/common-ui';
import { ISection } from '@gigin-work-space/model';
import { CandidateDataStore, empCBUsageStore, OrderServiceRequestIdStore } from '@gigin-work-space/store';
import {
  AUTO_HIDE_TIMEOUT,
  COLLECTION_GROUP_TYPE,
  DATA_STATUS,
  EmploymentReferenceCheckRelIds,
  STATUS,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../services';
import { FormFieldGenerator, MetaDetailsContainer } from '../common-ui';
import { EmploymentSection } from '../types/employment.types';
import { CommonBottomDrawerHoc } from './common-bottom-drawer-hoc';

export interface EditableContainerHocProps {
  sectionList?: ISection[];
  relId?: string;
  fields: any[];
  note: any;
  relIdGroupTitle: string;
  navigateToStep: (param: string) => void;
  handleConfirm: () => void;
}

export const EditableContainerHoc = (props: EditableContainerHocProps) => {
  const { sectionList, relId, fields, note, navigateToStep, relIdGroupTitle, handleConfirm } = props;

  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const { cbValue, targetField } = useSnapshot(empCBUsageStore);
  const { orderServiceRequestId } = useSnapshot(OrderServiceRequestIdStore);

  const { DCCandidateDataCollectionProgress } = useSnapshot(CandidateDataStore);
  const [selectedServiceRequestItemId, setSelectedServiceRequestItemId] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employmentChecks, setEmploymentChecks] = useState<EmploymentSection[]>([]);

  const navigate = useNavigate();

  // function to navigate to clicked section step if its completed
  const navigateToClickedStep = (data: any) => {
    navigateToStep(data.meta.goto.destination);
  };

  //   SIDE EFFECS
  useEffect(() => {
    const handleActiveStep = () => {
      if (sectionList) {
        const activeIndex = sectionList.findIndex((data) => data.rel_id === relId);
        if (activeIndex !== -1) {
          setCurrentActiveStep(activeIndex);
        } else {
          setCurrentActiveStep(0);
        }
      }
    };
    handleActiveStep();
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const stepWidth = 100;

      const scrollToPosition = currentActiveStep * stepWidth;

      const containerWidth = containerRef.current.offsetWidth;
      const excessWidth = Math.max(scrollToPosition - containerWidth / 2, 0);

      containerRef.current.scrollLeft = excessWidth;
    }
  }, [currentActiveStep]);

  const mapServiceRequestIds = (sectionList: any[], DCCandidateDataCollectionProgress: any[]) => {
    return sectionList.map((section) => {
      const match = DCCandidateDataCollectionProgress.find(
        (progress) =>
          progress.collectionType === section.rel_id &&
          [COLLECTION_GROUP_TYPE.employment_type, COLLECTION_GROUP_TYPE.reference_type].includes(
            progress.collectionGroupType,
          ),
      );

      return {
        ...section,
        serviceRequestItemId: match?.serviceRequestItemId || null,
      };
    });
  };

  const result = sectionList ? mapServiceRequestIds(sectionList, DCCandidateDataCollectionProgress) : [];

  const handleCheckboxClick = () => {
    setIsChecked(true);
    setOpenDialog(true);
    const matchedSection = result.find((section) => section.rel_id === relId);

    if (matchedSection) {
      const serviceRequestItemId = matchedSection.serviceRequestItemId;
      setSelectedServiceRequestItemId(serviceRequestItemId);
    }
  };

  const getCheckIds = () => {
    // Get the index of the current item
    const currentIndex = result.findIndex((item) => item.serviceRequestItemId === selectedServiceRequestItemId);
    // Get the base type (employment_check or reference_check)
    // Filter items that:
    // 1. Match the base type (employment_check or reference_check)
    // 2. Have an index greater than or equal to the current item
    // 3. Have a valid serviceRequestItemId
    const checkIds = result
      .filter(
        (item, index) =>
          EmploymentReferenceCheckRelIds.includes(item.rel_id) && index >= currentIndex && item.serviceRequestItemId,
      )
      .map((item) => item.serviceRequestItemId)
      .filter(Boolean); // Remove any null values

    return checkIds;
  };

  const handleContinueClick = async () => {
    const idToUse = orderServiceRequestId;

    if (!idToUse || !selectedServiceRequestItemId) {
      enqueueSnackbar('Missing required data. Please try again.', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
      navigate('/dashboard');
      return;
    }
    const checkIds = getCheckIds();

    try {
      setLoading(true);
      const payload = {
        status: STATUS.NOT_REQUIRED,
        isChecked: isChecked,
        checkIds: checkIds,
      };

      const response = await axiosInstance.patch(`${endpoints.PATCH_CHECK_DATA_COLLECTION_STATUS}/${idToUse}`, payload);

      if (!response) {
        throw new Error('Network response was not ok');
      }

      // On success
      setOpenDialog(false);
      handleConfirm();
    } catch (error) {
      enqueueSnackbar('Could not submit the data. Please try again later.', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.LONGER,
      });
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    setOpenDialog(false);
    setIsChecked(false);
  };

  const groupByBaseRelId = (data: EmploymentSection[]) => {
    const grouped: { [key: string]: EmploymentSection[] } = {};

    data.forEach((item) => {
      // Remove the last underscore and number from rel_id
      const baseRelId = item.rel_id.replace(/_\d+$/, '');

      // Skip employment_check_with_uan_2
      if (
        [COLLECTION_GROUP_TYPE.employment_check_with_uan_1, COLLECTION_GROUP_TYPE.employment_check_with_uan_2].includes(
          item.rel_id as COLLECTION_GROUP_TYPE,
        )
      )
        return;

      if (!grouped[baseRelId]) {
        grouped[baseRelId] = [];
      }
      grouped[baseRelId].push(item);
    });

    return grouped;
  };

  // Usage:
  const groupedResults = groupByBaseRelId(result);
  const employmentCheckArray = useMemo(() => groupedResults['employment_check'] || [], [groupedResults]);
  const referenceCheckArray = useMemo(() => groupedResults['reference_check'] || [], [groupedResults]);

  useEffect(() => {
    if (relId) {
      if (relId.startsWith('employment_check')) {
        setEmploymentChecks(employmentCheckArray);
      } else if (relId.startsWith('reference_check')) {
        setEmploymentChecks(referenceCheckArray);
      }
    }
  }, [relId, employmentCheckArray, referenceCheckArray]);

  return (
    <>
      {/* Stepper Section */}

      {sectionList && sectionList.length > 1 && (
        <section className="px-6 pt-6 w-full">
          <Box className="bk-dc-stepper-section">
            <div ref={containerRef} className="bk-stepper-scroll">
              <Stepper activeStep={currentActiveStep} alternativeLabel>
                {sectionList.map((data, id) => (
                  <Step
                    key={data.rel_id}
                    completed={
                      data.status?.toLowerCase() === DATA_STATUS.COMPLETED ||
                      data.status?.toLowerCase() === DATA_STATUS.NOT_REQUIRED
                    }
                    disabled={data.status?.toLowerCase() === DATA_STATUS.PENDING}
                    onClick={() => navigateToClickedStep(data)}>
                    <StepLabel className="completed-step-icon">{data.title.en}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <Box className="bk-stepper-side-blur" />
          </Box>
        </section>
      )}
      {/* Dynamic Form/Field Section */}
      <div className="w-full h-[2px]"></div>

      {COLLECTION_GROUP_TYPE.employment_detail === relIdGroupTitle &&
        currentActiveStep <= employmentChecks.length - 1 && (
          <FormControlLabel
            control={<Checkbox className="w-lg h-lg" checked={isChecked} onChange={handleCheckboxClick} />}
            label={
              <Typography className="bK-body1  ml-md">
                {currentActiveStep > 0
                  ? `Not Applicable - I have worked in only ${currentActiveStep} ${
                      currentActiveStep === 1 ? 'company' : 'companies'
                    }`
                  : 'Not Applicable - I am a fresher'}
              </Typography>
            }
            className="flex justify-start mt-lg ml-xl2"
          />
        )}

      {COLLECTION_GROUP_TYPE.reference_check === relIdGroupTitle &&
        currentActiveStep <= referenceCheckArray.length - 1 && (
          <FormControlLabel
            control={<Checkbox className="w-lg h-lg" checked={isChecked} onChange={handleCheckboxClick} />}
            label={
              <Typography className="bK-body1  ml-md">
                {currentActiveStep > 0
                  ? `Not Applicable - I have worked in only ${currentActiveStep} ${
                      currentActiveStep === 1 ? 'company' : 'companies'
                    }`
                  : 'Not Applicable - No references to provide'}
              </Typography>
            }
            className="flex justify-start mt-lg ml-xl2"
          />
        )}

      {openDialog && (
        <CommonBottomDrawerHoc isOpen={openDialog} handleClose={handleDrawerClose}>
          <Stack spacing={2}>
            <Typography className="bk-headline3 text-bk_text_secondary">
              Are you sure you do not have the requested details?
            </Typography>
            <MetaDetailsContainer
              iconName="info_yellow_icon.svg"
              noteText={
                <span>
                  <strong className="font-bold text-[15px]">Caution:</strong> Providing insufficient information would
                  affect the result & timeline of your background verification
                </span>
              }
              additionalNoteText="Connect with your HR if you are unsure of how to proceed further"
              fontStyle="bK-body1"
              bgColor="bk_tag_orange_bg"
              textColor="bk_text_secondary"
            />
            <Grid container alignItems={'center'}>
              <Grid item xs={6} paddingRight={'4px'}>
                <BKStyledButton
                  fullWidth
                  variant="outlined"
                  size="large"
                  className="!bK-body1"
                  onClick={handleDrawerClose}>
                  Go back
                </BKStyledButton>
                {/* the button i */}
              </Grid>
              <Grid item xs={6} paddingLeft={'4px'}>
                <BKStyledButton
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="!bK-body1"
                  onClick={handleContinueClick}>
                  {loading ? 'Submitting...' : 'Continue'}
                </BKStyledButton>
              </Grid>
            </Grid>
          </Stack>
        </CommonBottomDrawerHoc>
      )}

      <section className={sectionList && sectionList.length > 1 ? 'bk-main-stepper-section' : 'bk-main-section'}>
        <Grid container spacing={2} alignItems={'flex-start'} sx={{ pb: 2 }}>
          {fields &&
            fields.map((field) => (
              <Grid item xs={cbValue ? 12 : field.grid ? field.grid : 12} key={field.field_id}>
                <FormFieldGenerator params={field} />
              </Grid>
            ))}
          {note && (
            <Grid item xs={12}>
              <MetaDetailsContainer iconName="info_blue_icon.svg" noteText={note?.en} fontStyle="bk-sub-heading3" />
            </Grid>
          )}
        </Grid>
      </section>
    </>
  );
};
