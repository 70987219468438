import { Box, Typography } from '@mui/material';
import DisplayImage from './display-image';

export interface MetaDetailsContainerProps {
  iconName: string;
  noteText: string | React.ReactNode;
  fontStyle?: string;
  bgColor?: string;
  textColor?: string;
  additionalNoteText?: string;
}

export const MetaDetailsContainer = (props: MetaDetailsContainerProps) => {
  const {
    iconName,
    noteText,
    additionalNoteText,
    fontStyle = 'bk-sub-heading2',
    bgColor = 'bk_tag_blue_bg',
    textColor = 'bk_tag_blue_light',
  } = props;
  return (
    <div className={`flex flex-start gap-1 bg-${bgColor} py-4 px-3 rounded mt-2`}>
      <DisplayImage imageName={iconName} imageType="icon" width="20px" height="20px" />
      <Box>
        <Typography className={`${fontStyle} text-${textColor} text-justify`} style={{ whiteSpace: 'pre-line' }}>
          {noteText}
        </Typography>
        <Typography className={`${fontStyle} text-${textColor} text-justify mt-md`} style={{ whiteSpace: 'pre-line' }}>
          {additionalNoteText}
        </Typography>
      </Box>
    </div>
  );
};
